import React, { useState } from "react";
import Button from "./Button";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDeleteLeft, faSquare } from '@fortawesome/free-solid-svg-icons';

const Body = (props) => {
  const {onButtonClick, onClickDelete, onClickReset, onClickCalcul} = props;

  const [displayed, setDisplayed] = useState(false);

  const handleSquare = () => {
    setDisplayed(!displayed);
  }

  const handleButtonClick = (event) => {
    onButtonClick(event.target.innerText);
  }
  const reset = () => {};
  return (
    <div
      className={`grid ${
        displayed ? "grid-cols-5" : "grid-cols-4"
      } gap-4 h-1/2`}
    >
      {displayed && (
        <>
          <Button text="x^y" className="btn" />
          <Button text="lg" className="btn" />
          <Button text="ln" className="btn" />
          <Button text="(" className="btn" />
          <Button text=")" className="btn" />
        </>
      )}

      {displayed && (
        <Button text="√x" className="btn" />
      )}
      <Button text="AC" className="btn text-orange-500" onClick={() => onClickReset()} />
      <Button text={<FontAwesomeIcon icon={faDeleteLeft} />} className="btn text-orange-500" onClick={() => onClickDelete()}/>
      <Button text="%" className="btn text-orange-500" />
      <Button text="/" className="btn text-orange-500" />

      {displayed && (
        <Button text="x!" className="btn" />
      )}
      <Button text="7" className="btn" onClick={(event)=> handleButtonClick(event)}/>
      <Button text="8" className="btn" onClick={(event)=> handleButtonClick(event)}/>
      <Button text="9" className="btn" onClick={(event)=> handleButtonClick(event)}/>
      <Button text="*" className="btn text-orange-500" onClick={(event)=> handleButtonClick(event)}/>

      {displayed && (
        <Button text="1/x" className="btn" />
      )}
      <Button text="4" className="btn" onClick={(event)=> handleButtonClick(event)}/>
      <Button text="5" className="btn" onClick={(event)=> handleButtonClick(event)}/>
      <Button text="6" className="btn" onClick={(event)=> handleButtonClick(event)}/>
      <Button text="-" className="btn text-orange-500" onClick={(event)=> handleButtonClick(event)}/>

      {displayed && (
        <Button text="π" className="btn" />
      )}
      <Button text="1" className="btn" onClick={(event)=> handleButtonClick(event)}/>
      <Button text="2" className="btn" onClick={(event)=> handleButtonClick(event)}/>
      <Button text="3" className="btn" onClick={(event)=> handleButtonClick(event)}/>
      <Button text="+" className="btn text-orange-500" onClick={(event)=> handleButtonClick(event)}/>

      <Button 
        text={<Button text={<FontAwesomeIcon icon={faSquare} />} className="btn text-orange-500" />}
        className="btn text-orange-500"
        onClick={() => handleSquare()}
      />
      {displayed && (
        <Button text="e" className="btn" />
      )}
      <Button text="0" className="btn" onClick={(event)=> handleButtonClick(event)}/>
      <Button text="." className="btn" onClick={(event)=> handleButtonClick(event)}/>
      <Button text="=" className="btn-orange" onClick={() => onClickCalcul()} />
    </div>
  );
};

export default Body;
