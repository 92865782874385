import React, { useState } from "react";
import Screen from "../../Screen";
import Body from "../../Body";
import Navbar from "../../Navbar";

const Calculator = () => {
  const [screen, setScreen] = useState("");
  const [calcul, setCalcul] = useState("");

  const handleDisplayed = (value) => {
    setScreen(screen + value);
  }

  const handleDelete = () => {
    if (screen.length > 0) {
      setScreen(screen.slice(0, -1));
      setCalcul('')
    }
  }

  const handleClickReset = () => {
    if(screen.length > 0) {
      setScreen('');
      setCalcul('')
    }
  }

  const handleClickCalcul = () => {
    if (screen.length > 0) {
      try {
        const result = eval(screen);
        setCalcul(result.toString());
      } catch (error) {
        setCalcul("Erreur");
      }
    }
  }

  return (
    <div className="">
      <main className="flex flex-col gap-7 h-screen p-4 m-auto">
        <Screen input={screen} value={calcul}/>
        <Body 
          onButtonClick={handleDisplayed}
          onClickDelete={handleDelete}
          onClickReset={handleClickReset}
          onClickCalcul={handleClickCalcul}/>
      </main>
    </div>
  );
};

export default Calculator;