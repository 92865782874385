import React from "react";
import { Link } from "react-router-dom";

const LinkTool = (props) => {
  const { icon, name, link } = props;

  return (
    <Link to={link} className="text-center">
      <i>{icon}</i>
      <p>{name}</p>
    </Link>
  );
};

export default LinkTool;
