import React, { useEffect, useState } from "react";
import dayjs from "dayjs";
import "dayjs/locale/fr";
import { frFR } from "@mui/x-date-pickers/locales";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";

const Birthday = () => {
  const defaultDate = dayjs("2001-03-30");

  // select dates
  const [dateAge, setDateAge] = useState(defaultDate);
  const [dateToday, setTodayAge] = useState(dayjs());

  // vars date
  const [totalYears, setTotalYears] = useState(0);
  const [totalMonths, setTotalMonths] = useState(0);
  const [totalWeeks, setTotalWeeks] = useState(0);
  const [totalDays, setTotalDays] = useState(0);
  const [totalHours, setTotalHours] = useState(0);
  const [totalMinutes, setTotalMinutes] = useState(0);
  const [timeElapse, setTimeElapse] = useState(
    useState({
      years: 0,
      months: 0,
      days: 0,
    })
  );

  // Error
  const [error, setError] = useState(false);

  // change date & calcul
  useEffect(() => {
    const isDateValid = dateToday.isAfter(dateAge);

    if (isDateValid) {
      setError(false);

      const years = dateToday.diff(dateAge, "year");
      const months = dateToday.diff(dateAge, "month");
      const weeks = dateToday.diff(dateAge, "week");
      const days = dateToday.diff(dateAge, "day");
      const hours = dateToday.diff(dateAge, "hour");
      const minutes = dateToday.diff(dateAge, "minute");

      const monthsElapse = months - years * 12;
      const daysElapse = dateToday
        .subtract(years, "year")
        .subtract(monthsElapse, "month")
        .diff(dateAge, "day");
      setTimeElapse({
        years: years,
        months: monthsElapse,
        days: daysElapse,
      });

      setTotalYears(years);
      setTotalMonths(months);
      setTotalWeeks(weeks);
      setTotalDays(days);
      setTotalHours(hours);
      setTotalMinutes(minutes);
    } else {
      setError(true);
    }
  }, [dateAge, dateToday]);

  return (
    <main className="p-6 flex flex-col gap-6 max-w-sm m-auto text-gray-700">
      <LocalizationProvider
        dateAdapter={AdapterDayjs}
        adapterLocale="fr"
        localeText={
          frFR.components.MuiLocalizationProvider.defaultProps.localeText
        }
      >
        <DatePicker
          className="w-full border"
          label="Date de naissance"
          defaultValue={dateAge}
          views={["day", "month", "year"]}
          onChange={(changeDate) => setDateAge(changeDate)}
        />
        <DatePicker
          className="w-full"
          label="Ajourd'hui"
          defaultValue={dateToday}
          views={["day", "month", "year"]}
          onChange={(changeDate) => setTodayAge(changeDate)}
        />
      </LocalizationProvider>

      <section className="border border-gray-100 w-full rounded-2xl p-3">
        {error ? (
          <p className="text-red-500">
            La date d'aujourd'hui est antérieure à la date de naissance.
          </p>
        ) : (
          <>
            <div className="grid grid-cols-2 mb-4">
              {/* how old are u ? */}
              <div>
                <p className="text-2xl">Âge</p>
                <p>
                  <span className="text-2xl text-orange-500">
                    {timeElapse.years}
                  </span>{" "}
                  années
                </p>
                <p>
                  {timeElapse.months} mois | {timeElapse.days} jours
                </p>
              </div>
              {/* next birthday */}
              <div>
                <p>Anniverssaire suivant</p>
                <i>icon</i>
                <p>jour</p>
                <p>x mois | x jours</p>
              </div>
            </div>
            {/* summary */}
            <div className="text-center">
              <p className="text-lg text-orange-500 mb-3">Résumé</p>
              <div className="grid grid-cols-3 gap-4">
                <div>
                  <p className="text-sm text-gray-500">Années</p>
                  <p>{totalYears}</p>
                </div>
                <div>
                  <p className="text-sm text-gray-500">Mois</p>
                  <p>{totalMonths}</p>
                </div>
                <div>
                  <p className="text-sm text-gray-500">Semaines</p>
                  <p>{totalWeeks}</p>
                </div>
                <div>
                  <p className="text-sm text-gray-500">Jours</p>
                  <p>{totalDays}</p>
                </div>
                <div>
                  <p className="text-sm text-gray-500">Heures</p>
                  <p>{totalHours}</p>
                </div>
                <div>
                  <p className="text-sm text-gray-500">Minutes</p>
                  <p>{totalMinutes}</p>
                </div>
              </div>
            </div>
          </>
        )}
      </section>
    </main>
  );
};

export default Birthday;
