import React from 'react'

const Button = (props) => {
const{text, className, onClick} = props
  return (
    <div className={className} onClick={onClick}>
      {text}
    </div>
  )
}

export default Button 