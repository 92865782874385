import React from 'react'
import {BrowserRouter, Route, Routes} from 'react-router-dom';
import Calculator from './Pages/Calcualtor/Calculator';
import Navbar from './Navbar';
import NotFound from './NotFound';
import Historical from './Pages/Calcualtor/Historical';
import Tools from './Pages/Tools/Tools';
import Birthday from './Pages/Tools/Birthday';
import Area from './Pages/Tools/Area';
import Bmi from './Pages/Tools/Bmi';
import Data from './Pages/Tools/Data';
import Date from './Pages/Tools/Date';
import Promotion from './Pages/Tools/Promotion';
import Lenght from './Pages/Tools/Lenght';
import Weight from './Pages/Tools/Weight';
import Speed from './Pages/Tools/Speed';
import Temperature from './Pages/Tools/Temperature';
import Time from './Pages/Tools/Time';
import Volume from './Pages/Tools/Volume';

export default function index() {
    return (
      <BrowserRouter>
        <Navbar />
          <Routes>
              <Route path='/' element={<Calculator />} />
              <Route path='/tools' element={<Tools />} />
              <Route path='/historical' element={<Historical />} />

              {/* Tools */}
              <Route path='/birthday' element={<Birthday />} />
              <Route path='/area' element={<Area />} />
              <Route path='/bmi' element={<Bmi />} />
              <Route path='/data' element={<Data />} />
              <Route path='/date' element={<Date />} />
              <Route path='/promotion' element={<Promotion />} />
              <Route path='/lenght' element={<Lenght />} />
              <Route path='/weight' element={<Weight />} />
              <Route path='/speed' element={<Speed />} />
              <Route path='/temperature' element={<Temperature />} />
              <Route path='/time' element={<Time />} />
              <Route path='/volume' element={<Volume />} />

              <Route path='*' element={<NotFound />} />
          </Routes>
      </BrowserRouter>
    )
  }