import React, { useState } from 'react'

const Screen = (props) => {
  const {input, value} = props
  return (
    <div className='flex flex-col justify-end items-end h-1/2 text-2xl border-b border-white pb-2 font-secondary'>
      <div>{input ? input : 0}</div>
      <div>{value}</div>
    </div>
    
  )
}

export default Screen