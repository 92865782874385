import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEllipsisVertical,
  faGripVertical,
  faCalculator,
  faUpRightAndDownLeftFromCenter,
} from "@fortawesome/free-solid-svg-icons";
import { Link, NavLink, useLocation} from "react-router-dom";

const Navbar = () => {
  const location = useLocation();
  const locationIsHome = location.pathname === "/";

  return (
    <nav className="">
      <ul className="flex justify-around">
       {locationIsHome && (
          <li>
            <FontAwesomeIcon icon={faUpRightAndDownLeftFromCenter} />
          </li>
        )}
        <li>
          <NavLink
            to="/"
            className={({ isActive, isPending }) =>
              isPending ? "pending" : isActive ? "text-orange-500" : ""
            }
          >
            <FontAwesomeIcon icon={faCalculator} className="text-red" />
          </NavLink>
        </li>
        <li>
          <NavLink
            to="/tools"
            className={({ isActive, isPending }) =>
              isPending ? "pending" : isActive ? "text-orange-500" : ""
            }
          >
            <FontAwesomeIcon icon={faGripVertical} />
          </NavLink>
        </li>
        {locationIsHome && (
          <li>
            <FontAwesomeIcon icon={faEllipsisVertical} />
          </li>
        )}
      </ul>
    </nav>
  );
};

export default Navbar;
