import React from 'react'
import LinkTool from '../../LinkTool'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { 
  faBirthdayCake, faCalendarDays, 
  faRulerHorizontal,faWeightHanging,
  faGaugeHigh, faClock, faDatabase,
  faCube, faTemperatureThreeQuarters, 
  faWeightScale, faEuroSign} from '@fortawesome/free-solid-svg-icons';

const Tools = () => {
  return (
    <main className='grid grid-cols-3 gap-7 p-7'>
      <LinkTool 
        icon={<FontAwesomeIcon icon={faBirthdayCake}/>}
        name="Age"
        link="/birthday"
      />
      <LinkTool 
        icon={<FontAwesomeIcon icon={faBirthdayCake}/>}
        name="Aire"
        link="/area"
      />
        <LinkTool 
        icon={<FontAwesomeIcon icon={faWeightScale}/>}
        name="IMC"
        link="/bmi"
      />
      <LinkTool 
        icon={<FontAwesomeIcon icon={faDatabase}/>}
        name="Données"
        link="/data"
      />
      <LinkTool 
        icon={<FontAwesomeIcon icon={faCalendarDays}/>}
        name="Date"
        link="/date"
      />
      <LinkTool 
        icon={<FontAwesomeIcon icon={faEuroSign}/>}
        name="Promotion"
        link="/promotion"
      />
      <LinkTool 
        icon={<FontAwesomeIcon icon={faRulerHorizontal}/>}
        name="Longueur"
        link="/lenght"
      />
      <LinkTool 
        icon={<FontAwesomeIcon icon={faWeightHanging}/>}
        name="Masse"
        link="/weight"
      />
      <LinkTool 
        icon={<FontAwesomeIcon icon={faGaugeHigh}/>}
        name="Vitesse"
        link="/speed"
      />
        <LinkTool 
        icon={<FontAwesomeIcon icon={faTemperatureThreeQuarters}/>}
        name="Température"
        link="/temperature"
      />
      <LinkTool 
        icon={<FontAwesomeIcon icon={faClock}/>}
        name="Temps"
        link="/time"
      />
      <LinkTool 
        icon={<FontAwesomeIcon icon={faCube}/>}
        name="Volume"
        link="/volume"
      />
    </main>
  )
}

export default Tools